import React from 'react'
import s from './ForDesignerPage.module.sass'
function ForDesignerPage() {
    return (
        <div className={s.container}>

            <div className={s.mainText}>
                    <h1>Мы открыты к сотрудничеству с архитекторами и дизайнерами интерьеров</h1>
                <div className={s.wrapper}>
                    <div className={s.left}>
                        <p>
                        Здравствуйте, уважаемые коллеги, единомышленники и мастера своего дела. Мы молодая-растущая компания с огромным опытом наших мастеров и специалистов
    не стоящая на месте, а желающая всегда развиваться и совершенствоваться. Мы открыты к сотрудничеству и обмену опытом. Наш взгляд на создание мебели 
    гораздо шире нежели просто продать людям примитивные «коробушки». Мы создаем комфорт и уют при самых смелых и заветных пожеланиях наших заказчиков. 
    Ни для кого не секрет что при изготовлении мебели по проекту могут возникать своего рода нюансы-рекламации и это по сути рабочий момент,но такой 
    который может создать массу неудобств и потерю драгоценного времени заказчика, компании и сборщиков. Мы за экономию времени и за решения подобного 
    рода задач, если уж они все же возникли, решаем их максимально быстро. И подспорьем нам в этом служит цех расположенный в пределах Москвы в Царицыно. Что 
    позволяет решить проблему одним днем, а ни неделями либо несколькими днями. <br/>
    Политика нашей компании строится на простых и понятных качествах ясных каждому человеку. И когда мы говорим что: «открыты к сотрудничеству и обмену 
    опытом» мы не лукавим, а подкрепляем наши слова действиями нашего проекта «живая комната».
    <br/><b>«Живая комната»</b> это наш проект коворкинга.<br/> Из нашего опыта 
    знаем что  у многих дизайнеров и архитекторов работающих на фрилансе нет кабинетов и переговорных для встреч с заказчиками. Понимаем что это привносит
    свои неудобства при личных встречах и обсуждениях проектов. Так вот «живая комната» это отличное решение Ваших проблем с поиском и тратой времени и
    средств на аренду переговорных.Возникает объективный вопрос «как?». Все очень просто: когда у Вас предстоит встреча с клиентом, Вам необходимо лишь
    позвонить и забронировать у нас один из кабинетов в нашем шоу-руме. В Вашем распоряжении в свободном доступе: 
    <br/><br/>
    1. Шоу-рум в 5 минутах пешей доступности от 3-х станций метро <br/>
    2. Отдельный кабинет <br/>
    3. Мощный компьютер с большим монитором и  с необходимыми программами ( 3D-Max, ArhiCaD, фотошоп и т.д)<br/>
    4. Свободный доступ к нашим материалам и образцам <br/>
    5. Большой стол для выкладки образцов и комфортных переговоров обсуждения проектов<br/>
    6. Профессиональная кофе машина<br/>
                        </p>
                    </div>

                    <img className={s.right} src="/img.png" alt="" />
                </div>
                <p className={s.bottomP}>
                А самое главное что все это в дружественной и позитивной атмосфере. Ну и сейчас Вы думаете: «сколько же они сейчас за это все с меня запросят?».
    Выдохните и не напрягайтесь-Вы за эту услугу нам ничего не должны и не обязаны, но мы были бы рады и благодарны Вам если бы мебель присутствующая в
    Вашем проекте была бы размещена на изготовление в нашей компании.<br/>

    До скорых встреч в нашем шоу-руме! Творческих амбиций Вам и высоких полетов фантазий
                </p>
            </div>


            <div className={s.contacts}>
                <div className={s.wrapper}>
                    <div className={s.left}>
                        <h1>Персональная скидка и вознаграждение</h1>
                        <p>Обратитесь к руководителю компании MERBAU кухни</p>
                        <p>тел. +7 926 796 78 08 (писать в вотсап)</p>
                        <p>email: admin@merbau.pro</p>

                        <button>Свзяаться с руководителем</button>
                    </div>
                    <img className={s.right} src="https://pechenyi.com/info/dizajn-seroj-kuhni-13.jpg" alt="" />
                </div>
            </div>

            <div className={s.numerable}>
                <div className={s.wrapper}>
                    <div className={s.block}>
                        <h1 className={s.mainNumber}>1</h1>
                        <p className={s.parText}>Регистрация в партнерской программе</p>
                    </div>
                    <div className={s.block}>
                        <h1 className={s.mainNumber}>2</h1>
                        <p className={s.parText}>Визуализация и расчет проекта</p>
                    </div>
                    <div className={s.block}>
                        <h1 className={s.mainNumber}>3</h1>
                        <p className={s.parText}>Договор на изготовление мебели</p>
                    </div>
                    <div className={s.block}>
                        <h1 className={s.mainNumber}>4</h1>
                        <p className={s.parText}>Агентское вознаграждение</p>
                    </div>
                </div>
            </div>

            <img className={s.bottomImage} src="" alt="" />
            <div className={s.bottomSide}>
                <h1>Почему с нами выгодно?</h1>
                <p className={s.p_1}>◎ Реализовываем проекты любой сложности</p>
                <p className={s.p_2}>◎ Широкий ассортимент материалов</p>
                <p className={s.p_3}>◎ Ежемесячное обновление акций для клиентов</p>
                <p className={s.p_4}>◎ Быстрые сроки изготовления мебели</p>
                <p className={s.p_5}>◎ Широкий выбор бытовой техники и аксессуаров для кухни</p>
                <p className={s.p_6}>◎ Выгодные условия сотрудничества с дизайнерами</p>
                <div className={s.btnWrapper}>
                    <button>Стать партнёром</button>
                </div>
            </div>

        </div>
    )
}

export default ForDesignerPage