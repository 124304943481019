import React from 'react'
import s from './footer.module.sass'
import { useNavigate } from 'react-router-dom'
import logo from '../../images/logo.jpg'

function Footer() {
    const navigate = useNavigate()

      
    return (
        <div className={s.container}>
            <div className={s.innerContainer}>
                <div className={s.left}>
                    <img src={logo} alt="logo" />
                </div>

                <div className={s.right}>
                    <p>г. Москва, Бескудниковский бульвар 11 к2</p>
                    <p>тел. 8-926-796-78-08</p>
                    <p>почта: admin@merbau.pro</p><br/>
                    <p>график работы</p>
                    <p>11.00 - 21.00</p>
                    <p>без выходных</p>

                    <div className={s.socialMedias}>
                        <img src="https://cdn-icons-png.freepik.com/256/3536/3536661.png?semt=ais_hybrid" alt="icon" />
                        <img src="/whatsapp.png" alt="icon" />
                        <img src="https://cdn-icons-png.flaticon.com/512/4096/4096116.png" alt="icon" />
                        <img src="https://cdn-icons-png.flaticon.com/512/5968/5968835.png" alt="icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer