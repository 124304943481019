import React from 'react'
import s from './aboutUs.module.sass'
import Button from '../../components/getConsultationButton/Button'

function AboutUs() {
  return (
    <div className={s.container}>
        <div className={s.innerContainer}>
            <div className={s.mainText}>О нас</div>
            <div className={s.middleText}>
                <p className={s.left}>
                Здравствуйте, меня зовут Андрей Сава и я основатель компании MERBAU. Я вырос в семье мебельщиков и часто проводил свободное от учебы время
на мебельной фабрике восторгаясь результатами труда моих родных.<br/> Влюбляясь и вдохновляясь семейным делом я с гордостью сегодня являюсь его продолжателем.
Мой общий профессиональный стаж в этой сфере более 30 лет. И с полной уверенностью могу сказать что данный вид деятельности является не посредственным
видом заработком, а любимым хобби, что делает нас более пластичными к требованиям и виденью заказчика позволяя реализовывать необычный, функциональный
и действительно индивидуальный проект для конкретного клиента. <br/><br/>Мотивацией для основания компании было не только желание продолжать семейное дело,
 но и несогласие с примитивной, недобросовестной и финансово подлой позицией и виденьем компаний где приходилось работать. Команда же наших специалистов
является высококвалифицированными людьми любящими свою профессию и разделяющими политику компании, которая направлена не просто на дизайн, изготовление
и сборку мебели, а подкреплена более глубокой философией.<br/><br/> Мы это скорее эмоции и чувства которые вы испытываете и которыми наполняетесь возвращаясь домой.
Большая радость для нас видеть ваши счастливые лица по результатам трансформаций вашего дома при нашем прямом участии.
Ваши пожелания плюс наш подход и опыт - это и есть залог комфорта и уюта Вашего дома!
<br/><br/>
С уважением, Андрей Сава


                </p>
                <img className={s.right} src="https://pinskdrev.kz/web/catalogfiles/catalog/offers/3-14.jpg" alt="das" />
            </div>
        <div className={s.bottomSide}>
            <img src="https://pechenyi.com/info/dizajn-kuhni-2024-108-1.jpg" className={s.left} alt="" />
            <div className={s.right}>
                <h1>Как заказать мебель в MERBAU</h1>
                <p>Вы можете выслать проект  на почту или получить консультацию, если еще не определились с дизайном и материалами.  Ждем вас в нашем салоне по адресу Бескудниковский бульвар 11 к2. Мы поможем подобрать фурнитуру, наполнение и варианты отделок.
<br/><br/>
Подготовим эскизы и смету мебели. Срок изготовления зависит от сложности и в среднем составляет 3 недели. Далее наши монтажники установят изделие. Остались вопросы? Мы с радостью ответим на все.</p>

            <Button></Button>
            </div>
        </div>
        
        
        </div>
        

    </div>
  )
}

export default AboutUs