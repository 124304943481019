import React, { useEffect, useState } from 'react';
import s from './Slider.module.sass';
import { useNavigate } from 'react-router-dom';
import sliderArrow from '../../images/icons/slider_arrow.png'



function Slider({data}) {
    const [currentImage, setCurrentImage] = useState(0)
    const {name, titleText, parText} = data
    const arr = ['1', '2', '3', '4', '5']

    const [images, setImages] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        for(let i = 1; i <= 5; i++){
            setImages(prev => [...prev, `./сжатые_монтажи1/${name}/${i}.jpg`])
        }
    }, [data])



    const changeIndex = (e, index) => {
        e.stopPropagation()
        if (currentImage + index == images.length) {
            setCurrentImage(0)
            
        } else if (currentImage + index < 0) {
            setCurrentImage(images.length - 1)
        }
        else {
            setCurrentImage(prev => prev + index);
        }
    }

    const handleChooseImage = (index) => {
        setCurrentImage(index)
    }

    return (
        <div className={s.container}>
        <meta httpEquiv="Cache-Control" content="max-age=3600"></meta>


        <div className={s.left}>
            <div className={s.prew} onClick={() => {navigate(`${data.titleText}`)}} style={{cursor: 'pointer'}}>
                <button onClick={(e) => { changeIndex(e, -1) }} className={s.leftButton}><img src={sliderArrow} alt="" /></button>
                <img src={images[currentImage]} alt="preview" />
                <button onClick={(e) => { changeIndex(e, 1) }} className={s.rightButton}><img src={sliderArrow} alt="" /></button>
            </div>
            <div className={s.rulet}>
                {images ? (
                    images.map((image, index) => (
                        <img
                            onClick={() => {handleChooseImage(index)}}
                            src={image}
                            key={index}
                            alt={`image-${index}`}
                            id={index == currentImage ? s.light : null}
                        />
                    ))
                ) : null}
            </div>
        </div>


            <div className={s.right}>
                <h2 className={s.title}>{data ? data.titleText : null}</h2>
                <p className={s.par}>{data ? data.parText : null}</p>
            </div>
        </div>
    );
}

export default Slider;
